import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import KitImageDisplay from "./components/KitImageDisplay";
import LeagueGtk from "./components/LeagueGtk";
import Header from "./components/Header";
import "./App.css";
import { Analytics } from "@vercel/analytics/react";

const App: React.FC = () => {
  const MainContent = () => (
    <div className="main-content">
      <h1 style={{ color: "black" }}>
        <p style={{ margin: 0 }}>Welcome to Guess the Kit!</p>
        <p style={{ margin: 5 }}>
          The ultimate guessing game for football enthusiasts.
        </p>
        <p style={{ margin: 5 }}>
          Can you recognize kits from the Premier League, La Liga, and more?
        </p>
      </h1>
      {/* <h2 style={{ color: "black" }}>
        Please select a game mode to start guessing!
      </h2> */}
      <Link to="/wordle">
        <button className="route-button">Wordle Mode </button>
      </Link>
      <Link to="/league-mode">
        <button className="route-button">League Mode</button>
      </Link>
    </div>
  );

  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header title="Guess the kit" />
                <MainContent />
              </>
            }
          />
          <Route
            path="/wordle"
            element={
              <>
                <KitImageDisplay />
              </>
            }
          />
          <Route
            path="/league-mode"
            element={
              <>
                <LeagueGtk />
              </>
            }
          />
        </Routes>
        <Analytics />
      </div>
    </Router>
  );
};

export default App;
