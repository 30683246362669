import React, { useState, useEffect } from "react";
import { UseKitFromLeague, useLeagues, useTeams } from "../hooks/KitHooks";
import "../styles/league-gtk.css";
import Header from "./Header";
import resetIcon from "../reset.png";

const KitLeagueGuessingGame: React.FC = () => {
  const [selectedLeagueId, setSelectedLeagueId] = useState<number | null>(null);
  const { kitImage, fetchKitImage, isLoading } =
    UseKitFromLeague(selectedLeagueId);
  const leagues = useLeagues();
  const teams = useTeams(selectedLeagueId);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [season, setSeason] = useState("");
  const [kitNumber, setKitNumber] = useState<number | null>(null);
  const [resultMessage, setResultMessage] = useState("");
  const [result, setResult] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [selectedLeague, setSelectedLeague] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [newAttemptIndex, setNewAttemptIndex] = useState<number | null>(null); // To track the new attempt index
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [guessHistory, setguessHistory] = useState<
    {
      teamName: string;
      season: string;
      kitNumber: string;
      indicators: {
        teamNameCorrect: boolean;
        seasonIndicator: string;
        kitNumberIndicator: string;
      };
    }[]
  >([]);

  const handleLeagueSelection = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const leagueId = Number(event.target.value);
    console.log(selectedLeagueId);

    if (leagueId === -1) return; // Early exit if no valid league is selected

    // Check if a different league is selected mid-game
    if (selectedLeagueId && leagueId !== selectedLeagueId) {
      resetGame(); // Reset the game if changing leagues mid-game
    }

    setSelectedLeagueId(leagueId);
    setSelectedLeague(event.target.options[event.target.selectedIndex].text);

    fetchKitImage();
  };

  const getKitNumberText = (kitNumber: number | null): string => {
    switch (kitNumber) {
      case 0:
        return "Home";
      case 1:
        return "Away";
      case 2:
        return "Third";
      default:
        return "Unknown";
    }
  };

  const convertSeason = (season: string) => {
    const endYear = 2000 + parseInt(season);
    const startYear = endYear - 1;
    return `${startYear}/${endYear}`;
  };

  const handleSubmit = () => {
    if (!kitImage) return;

    if (!teamName || !season || kitNumber === null) {
      setErrorMessage(
        "Please select a team, season, and kit type before submitting a guess."
      );
      return;
    }

    setIsButtonDisabled(true);

    setTimeout(() => {
      console.log("Sett tilbake");
      setIsButtonDisabled(false);
    }, 500);

    setErrorMessage(null);

    setAttempts((prevAttempts) => prevAttempts + 1);

    const teamNameCorrect = teamName === kitImage.teamName;

    const seasonIndicator =
      season === kitImage.season
        ? "correct"
        : season > kitImage.season
        ? "down-icon"
        : "up-icon";
    const kitNumberIndicator =
      kitNumber === kitImage.kitNumber
        ? "correct"
        : kitNumber! < kitImage.kitNumber
        ? "down-icon"
        : "up-icon";

    setIsSubmitted(true);

    setguessHistory((prevHistory) => [
      {
        teamName,
        season: convertSeason(season),
        kitNumber: getKitNumberText(kitNumber),
        indicators: {
          teamNameCorrect,
          seasonIndicator,
          kitNumberIndicator,
        },
      },
      ...prevHistory,
    ]);
    setNewAttemptIndex(0);

    if (
      teamNameCorrect &&
      season === kitImage.season &&
      kitNumber === kitImage.kitNumber
    ) {
      setTimeout(() => {
        setResultMessage(`You guessed the ${kitImage.league} kit in ${
          attempts + 1
        } attempt(s)! 
        Team: ${kitImage.teamName}
        League: ${kitImage.league}
        Season: ${convertSeason(kitImage.season)}
        Kit type: ${getKitNumberText(kitImage.kitNumber)}`);

        setShowMessageBox(true);
        setIsButtonDisabled(true);
        setGameOver(true);
      }, 500);
    } else if (attempts === 7) {
      setTimeout(() => {
        setResultMessage(
          `You used up all your 8 attempts!
          Team: ${kitImage.teamName}
          League: ${kitImage.league}
          Season: ${convertSeason(kitImage.season)}
          Kit type: ${getKitNumberText(kitImage.kitNumber)}`
        );
        setShowMessageBox(true);
        setIsButtonDisabled(true);
        setGameOver(true);
      }, 500);
    }
  };

  const resetGame = () => {
    setTeamName("");
    setSeason("");
    setKitNumber(null);
    setIsSubmitted(false);
    setguessHistory([]);
    setResult("");
    setAttempts(0);
    setShowMessageBox(false);
    setIsButtonDisabled(false);
    setGameOver(false);
  };

  useEffect(() => {
    if (guessHistory.length > 0) {
      const timeoutId = setTimeout(() => {
        setNewAttemptIndex(null); // Remove the class after the animation completes
      }, 800);

      return () => clearTimeout(timeoutId);
    }
  }, [guessHistory]);

  const handleNextKit = () => {
    resetGame();
    setSelectedLeagueId(null);
    setTimeout(() => {
      setSelectedLeagueId(selectedLeagueId);
    }, 0);
  };
  const leagueDropdown = (
    <select
      id="league-select"
      onChange={handleLeagueSelection}
      value={selectedLeagueId ?? ""}
    >
      <option value="-1">--Select league--</option>
      {leagues.map((league) => (
        <option key={league.leagueID} value={league.leagueID}>
          {league.leagueName}
        </option>
      ))}
    </select>
  );

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="football"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="league-mode-container">
      <Header
        title="Guess the kit"
        buttons={[
          { label: "Home", path: "/", className: "button-home" },
          {
            label: "Wordle",
            path: "/wordle",
            className: "button-wordle",
          },
        ]}
        dropdown={selectedLeagueId ? leagueDropdown : null} // Show dropdown in header if a league is selected
      />
      {!selectedLeagueId && (
        <div className="initial-league-select" style={{ marginTop: "-30px" }}>
          <p
            style={{ color: "black", fontSize: "1.4em" }}
            className="instruction-text"
          >
            Select a league to get started
          </p>
          {leagueDropdown}
        </div>
      )}
      {selectedLeagueId && (
        <div className="container">
          {kitImage ? (
            <div className="image-wrapper">
              <img src={kitImage.url} alt="Kit Image" className="image" />
              {gameOver && (
                <img
                  src={resetIcon}
                  alt="Reset"
                  className="reset-icon"
                  onClick={handleNextKit}
                  title="New kit"
                />
              )}
            </div>
          ) : (
            <p>No kit image available</p>
          )}

          <div className="game-container2">
            <div className="categories">
              <div className="category">
                <label className="dropdown-label">
                  Team Name:
                  <select
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                  >
                    <option value="">Select Team</option>
                    {teams.map((team) => (
                      <option key={team.teamID} value={team.teamName}>
                        {team.teamName}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="category">
                <label className="dropdown-label">
                  Season:
                  <select
                    value={season}
                    onChange={(e) => setSeason(e.target.value)}
                  >
                    <option value="">Select Season</option>
                    <option value="25">24/25</option>
                    <option value="24">23/24</option>
                    <option value="23">22/23</option>
                    <option value="22">21/22</option>
                    <option value="21">20/21</option>
                    <option value="20">19/20</option>
                    <option value="19">18/19</option>
                  </select>
                </label>
              </div>

              <div className="category">
                <label className="dropdown-label">
                  Kit type:
                  <select
                    value={kitNumber ?? ""}
                    onChange={(e) => setKitNumber(Number(e.target.value))}
                  >
                    <option value="">Select Kit type</option>
                    <option value="0">Home</option>
                    <option value="1">Away</option>
                    <option value="2">Third</option>
                  </select>
                </label>
              </div>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <button
              className={`guess-button ${isButtonDisabled ? "disabled" : ""}`}
              onClick={handleSubmit}
              disabled={isButtonDisabled} // This makes the button unclickable
            >
              {gameOver ? "" : `Guess ${attempts + 1}/8`}
            </button>
            <div className="guess-history">
              {guessHistory.map((attempt, index) => (
                <div
                  key={index}
                  className={`guess-row ${
                    index === newAttemptIndex ? "new-guess" : ""
                  }`}
                >
                  <div className="icon-container">
                    <div
                      className={`status-icon ${
                        attempt.indicators.teamNameCorrect
                          ? "correct"
                          : "incorrect"
                      }`}
                    ></div>
                    <div className="icon-label">{attempt.teamName}</div>
                  </div>
                  <div className="icon-container">
                    <div
                      className={`direction-icon ${attempt.indicators.seasonIndicator}`}
                    ></div>
                    <div className="icon-label">{attempt.season}</div>
                  </div>
                  <div className="icon-container">
                    <div
                      className={`direction-icon ${attempt.indicators.kitNumberIndicator}`}
                    ></div>
                    <div className="icon-label">{attempt.kitNumber}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {showMessageBox && (
            <div className="message-box2">
              <button
                className="close-button"
                onClick={() => setShowMessageBox(false)}
              >
                &times;
              </button>
              <p>{resultMessage}</p>
              <button onClick={handleNextKit}>New Kit</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default KitLeagueGuessingGame;
