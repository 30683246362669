import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useKitImage, useLeagues, useTeams } from "../hooks/KitHooks";
import "../styles/gtk.css";
import fireIcon from "../streak2.png";
import Header from "./Header";

const KitGuessingGame: React.FC = () => {
  const { kitImage, fetchKitImage } = useKitImage();
  const leagues = useLeagues();
  const [selectedLeagueId, setSelectedLeagueId] = useState<number | null>(null);
  const teams = useTeams(selectedLeagueId);
  const [teamName, setTeamName] = useState("");
  const [season, setSeason] = useState("");
  const [kitNumber, setKitNumber] = useState<number | null>(null);
  const [attempts, setAttempts] = useState(0);
  const [streak, setStreak] = useState<number>(0);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [finishText, setFinishText] = useState<string | null>(null);
  const [gameOver, setGameOver] = useState(false);
  const [attemptHistory, setAttemptHistory] = useState<
    {
      teamName: string;
      league: string;
      season: string;
      kitNumber: string;
      indicators: {
        teamNameCorrect: boolean;
        leagueCorrect: boolean;
        seasonIndicator: string;
        kitNumberIndicator: string;
      };
    }[]
  >([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [newAttemptIndex, setNewAttemptIndex] = useState<number | null>(null);
  const [resultMessage, setResultMessage] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["guessedKit"]);

  const getKitNumberText = (kitNumber: number | null): string => {
    switch (kitNumber) {
      case 0:
        return "Home";
      case 1:
        return "Away";
      case 2:
        return "Third";
      default:
        return "Unknown";
    }
  };

  const convertSeason = (season: string) => {
    const endYear = 2000 + parseInt(season);
    const startYear = endYear - 1;
    return `${startYear}/${endYear}`;
  };

  const handleSubmit = () => {
    if (!kitImage) return;

    if (!selectedLeagueId || !teamName || !season || kitNumber === null) {
      setErrorMessage(
        "Please select a league, team, season, and kit before submitting a guess."
      );
      return;
    }

    setIsButtonDisabled(true);

    setTimeout(() => {
      console.log("Sett tilbake");
      setIsButtonDisabled(false);
    }, 500);
    setErrorMessage(null);

    setAttempts((prevAttempts) => prevAttempts + 1);

    const teamNameCorrect = teamName === kitImage.teamName;
    const leagueCorrect =
      leagues.find((l) => l.leagueID === selectedLeagueId)?.leagueName ===
      kitImage.league;
    const seasonIndicator =
      season === kitImage.season
        ? "correct"
        : season > kitImage.season
        ? "down-arrow"
        : "up-arrow";
    const kitNumberIndicator =
      kitNumber === kitImage.kitNumber
        ? "correct"
        : kitNumber! < kitImage.kitNumber
        ? "down-arrow"
        : "up-arrow";

    const newAttempt = {
      teamName,
      league:
        leagues.find((l) => l.leagueID === selectedLeagueId)?.leagueName ?? "",
      season: convertSeason(season),
      kitNumber: getKitNumberText(kitNumber),
      indicators: {
        teamNameCorrect,
        leagueCorrect,
        seasonIndicator,
        kitNumberIndicator,
      },
    };

    setAttemptHistory((prevHistory) => [newAttempt, ...prevHistory]);
    setNewAttemptIndex(0);
  };

  useEffect(() => {
    if (attemptHistory.length === 0) return;

    if (kitImage === null) return;

    const latestAttempt = attemptHistory[0]; // The most recent attempt is the first in the array
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setHours(24, 0, 0, 0);

    if (
      latestAttempt.indicators.teamNameCorrect &&
      latestAttempt.indicators.leagueCorrect &&
      latestAttempt.season === convertSeason(kitImage!.season) &&
      latestAttempt.kitNumber === getKitNumberText(kitImage!.kitNumber)
    ) {
      //Correct guess
      playSound();
      const updatedStreak = streak + 1;
      setStreak(updatedStreak);

      // Save the streak to localStorage along with today's date
      const streakData = {
        streak: updatedStreak,
        lastCorrectDate: today.toISOString().split("T")[0],
      };
      localStorage.setItem("streakData", JSON.stringify(streakData));
      setTimeout(() => {
        setResultMessage(
          `You guessed todays kit correctly in ${attempts} attempt(s)! 
        Team: ${kitImage!.teamName}
        League: ${kitImage!.league}
        Season: ${convertSeason(kitImage!.season)}
        Kit type: ${getKitNumberText(kitImage!.kitNumber)}`
        );
        setShowMessageBox(true);
        setIsButtonDisabled(true);
        setGameOver(true);
        setCookie("guessedKit", today.toISOString().split("T")[0], {
          path: "/",
          expires: tomorrow,
        });
        saveLastAttempt();
      }, 500);
      setIsDisabled(true);
    } else if (attempts === 7) {
      setTimeout(() => {
        setResultMessage(
          `You used up all your 8 attempts!
          Team: ${kitImage!.teamName}
          League: ${kitImage!.league}
          Season: ${convertSeason(kitImage!.season)}
          Kit type: ${getKitNumberText(kitImage!.kitNumber)}`
        );
        setIsButtonDisabled(true);
        setShowMessageBox(true);
        setGameOver(true);
        setCookie("guessedKit", today.toISOString().split("T")[0], {
          path: "/",
          expires: tomorrow,
        });
        saveLastAttempt();
        setStreak(0);
      }, 500);
      setIsDisabled(true);
    }
  }, [attemptHistory]); // This useEffect runs whenever attemptHistory changes

  useEffect(() => {
    if (attemptHistory.length > 0) {
      const timeoutId = setTimeout(() => {
        setNewAttemptIndex(null); // Remove the class after the animation completes
      }, 800);

      return () => clearTimeout(timeoutId);
    }
  }, [attemptHistory]);

  const handleClose = () => {
    setShowMessageBox(false);
  };

  const playSound = () => {
    const audio = new Audio(require("../cheer2.mp3"));
    audio.play().catch((error) => {
      console.error("Error playing audio:", error);
    });
  };

  const saveLastAttempt = () => {
    const today = new Date().toISOString().split("T")[0];
    const data = {
      date: today,
      history: attemptHistory,
    };
    localStorage.setItem("attemptHistory", JSON.stringify(data));
  };

  const getLastAttempt = () => {
    const dataStr = localStorage.getItem("attemptHistory");
    if (!dataStr) return;

    const data = JSON.parse(dataStr);
    const today = new Date().toISOString().split("T")[0];

    if (data.date !== today) {
      localStorage.removeItem("attemptHistory");
      return;
    }
    setAttemptHistory(data.history);
  };

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    if (cookies.guessedKit === today) {
      setIsButtonDisabled(true);
      setIsDisabled(true);
      getLastAttempt();
      setFinishText("Come back tomorrow or play league mode");
      setGameOver(true);
    }
  }, []);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const savedDataStr = localStorage.getItem("attemptHistory");

    if (savedDataStr) {
      const savedData = JSON.parse(savedDataStr);

      // Check if saved date is not today to remove old data
      if (savedData.date !== today) {
        localStorage.removeItem("attemptHistory"); // Remove from local storage
        removeCookie("guessedKit", { path: "/" }); // Remove cookie
      }
    }

    //Check if the streak is continously
    const streakData = localStorage.getItem("streakData");
    if (streakData) {
      const { streak, lastCorrectDate } = JSON.parse(streakData);
      const today = new Date().toISOString().split("T")[0];
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (lastCorrectDate === yesterday.toISOString().split("T")[0]) {
        //Check if streak is continous
        setStreak(streak);
      } else if (lastCorrectDate !== today) {
        //Not continous so we remove streak
        setStreak(0);
        localStorage.removeItem("streakData");
      } else {
        //Update streak so it gets updated accordingly
        setStreak(streak);
      }
    }
  }, []);

  if (!kitImage) {
    return (
      <div className="loading-screen">
        <div className="football"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="container">
      <Header
        title="Guess the Kit"
        buttons={[
          { label: "Home", path: "/", className: "button-home" },
          {
            label: "League Mode",
            path: "/league-mode",
            className: "button-league",
          },
        ]}
      />
      <div className="finish-text">{finishText}</div>
      {streak > 0 && (
        <div className="streak-container">
          <span className="streak-number">{streak}</span>
          <img
            src={fireIcon}
            alt="Streak fire icon"
            className="streak-fire-icon"
          />
        </div>
      )}
      <img src={kitImage.url} alt="Kit Image" className="image-wordle" />
      <div className="game-container">
        <div className="categories-wordle">
          <div className="category-wordle">
            <label className="dropdown-label2">
              League:
              <select
                disabled={isDisabled}
                value={selectedLeagueId ?? ""}
                onChange={(e) => {
                  const selectedId = Number(e.target.value);
                  setSelectedLeagueId(selectedId);
                }}
              >
                <option value="">Select League</option>
                {leagues.map((league) => (
                  <option key={league.leagueID} value={league.leagueID}>
                    {league.leagueName}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="category-wordle">
            <label className="dropdown-label2">
              Team Name:
              <select
                disabled={isDisabled}
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
              >
                <option value="">Select Team</option>
                {teams.map((team) => (
                  <option key={team.teamID} value={team.teamName}>
                    {team.teamName}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="category-wordle">
            <label className="dropdown-label2">
              Season:
              <select
                disabled={isDisabled}
                value={season}
                onChange={(e) => setSeason(e.target.value)}
              >
                <option value="">Select Season</option>
                <option value="25">24/25</option>
                <option value="24">23/24</option>
                <option value="23">22/23</option>
                <option value="22">21/22</option>
                <option value="21">20/21</option>
                <option value="20">19/20</option>
                <option value="19">18/19</option>
              </select>
            </label>
          </div>
          <div className="category-wordle">
            <label className="dropdown-label2">
              Kit type:
              <select
                disabled={isDisabled}
                value={kitNumber ?? ""}
                onChange={(e) => setKitNumber(Number(e.target.value))}
              >
                <option value="">Select Kit type</option>
                <option value="0">Home</option>
                <option value="1">Away</option>
                <option value="2">Third</option>
              </select>
            </label>
          </div>
        </div>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <button
          className="guess-button2"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          {gameOver ? "" : `Guess ${attempts + 1}/8`}
        </button>

        <div className="attempt-history">
          {attemptHistory.map((attempt, index) => (
            <div
              key={index}
              className={`attempt-row ${
                index === newAttemptIndex ? "new-attempt" : ""
              }`}
            >
              <div className="circle-container">
                <div
                  className={`circle ${
                    attempt.indicators.leagueCorrect ? "correct" : "incorrect"
                  }`}
                ></div>
                <div className="circle-label">{attempt.league}</div>
              </div>
              <div className="circle-container">
                <div
                  className={`circle ${
                    attempt.indicators.teamNameCorrect ? "correct" : "incorrect"
                  }`}
                ></div>
                <div className="circle-label">{attempt.teamName}</div>
              </div>
              <div className="circle-container">
                <div
                  className={`arrow ${attempt.indicators.seasonIndicator}`}
                ></div>
                <div className="circle-label">{attempt.season}</div>
              </div>
              <div className="circle-container">
                <div
                  className={`arrow ${attempt.indicators.kitNumberIndicator}`}
                ></div>
                <div className="circle-label">{attempt.kitNumber}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showMessageBox && (
        <div className="message-box">
          <p>{resultMessage}</p>
          <button onClick={handleClose}>Close</button>
        </div>
      )}
    </div>
  );
};

export default KitGuessingGame;
