import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo.png";

const Header: React.FC<{
  title: string;
  buttons?: { label: string; path: string; className?: string }[];
  dropdown?: React.ReactNode;
}> = ({ title, buttons, dropdown }) => {
  return (
    <header className="header">
      <div className="header-content">
        <Link to="/">
          <img src={logo} alt="Guess the Kit Logo" className="logo" />
        </Link>
        <h1>{title}</h1>
      </div>
      <div className="header-controls">
        {buttons && (
          <nav className="button-nav">
            {buttons.map((button, index) => (
              <Link key={index} to={button.path}>
                <button className={button.className}>{button.label}</button>
              </Link>
            ))}
            <a
              href="https://www.linkedin.com/in/daniel-nguyen-ab93101b8/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="contact-button">Contact</button>
            </a>
          </nav>
        )}
        {dropdown && <div className="header-dropdown">{dropdown}</div>}
      </div>
    </header>
  );
};

export default Header;
